exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.HomePage_font-title__3qgCC {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.HomePage_font-body__3gGtJ {\n  font-family: \"Lato\", sans-serif; }\n\n.HomePage_font-secondary__16keY {\n  font-family: \"Lato\", sans-serif; }\n\n.HomePage_font-input__25Mgg {\n  font-family: \"Lato\", sans-serif; }\n\n.HomePage_font-link__3ZlPo {\n  font-family: \"Lato\", sans-serif; }\n\n\n.HomePage_hide-if-only-child__1WAom:only-child {\n  display: none; }\n\n\n.HomePage_hide-if-last-child__3wSQ7:last-child {\n  display: none; }\n\n\n.HomePage_no-margin-if-last__Qkrg4:last-child {\n  margin-bottom: 0; }\n\n.HomePage_loginForm__2f5En,\n.HomePage_with-shadow__3pJu3 {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n\n.HomePage_with-shadow-hover__13Doz {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  \n  .HomePage_with-shadow-hover__13Doz:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.HomePage_coverImage__3RKj2 {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  z-index: 1; }\n\n.HomePage_content__fLvqR {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  padding: 2rem;\n  background: rgba(0, 0, 0, 0.4);\n  z-index: 2;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: center;\n  overflow: scroll; }\n\n.HomePage_logo__2xTBr {\n  max-width: 600px;\n  width: 100%; }\n\n.HomePage_loginForm__2f5En {\n  background: white;\n  max-width: 600px;\n  width: 100%;\n  padding: 1rem;\n  border-radius: 7px; }\n\n.HomePage_label__ovTTp {\n  display: block;\n  font-weight: bold;\n  padding: 5px; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "HomePage_font-title__3qgCC",
	"font-body": "HomePage_font-body__3gGtJ",
	"font-secondary": "HomePage_font-secondary__16keY",
	"font-input": "HomePage_font-input__25Mgg",
	"font-link": "HomePage_font-link__3ZlPo",
	"hide-if-only-child": "HomePage_hide-if-only-child__1WAom",
	"hide-if-last-child": "HomePage_hide-if-last-child__3wSQ7",
	"no-margin-if-last": "HomePage_no-margin-if-last__Qkrg4",
	"loginForm": "HomePage_loginForm__2f5En",
	"with-shadow": "HomePage_with-shadow__3pJu3",
	"with-shadow-hover": "HomePage_with-shadow-hover__13Doz",
	"coverImage": "HomePage_coverImage__3RKj2",
	"content": "HomePage_content__fLvqR",
	"logo": "HomePage_logo__2xTBr",
	"label": "HomePage_label__ovTTp"
};