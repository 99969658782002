exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.Markdown_font-title__2rX_J {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.Markdown_font-body__2vsOu {\n  font-family: \"Lato\", sans-serif; }\n\n.Markdown_font-secondary__3ewOv {\n  font-family: \"Lato\", sans-serif; }\n\n.Markdown_font-input__2FDtG {\n  font-family: \"Lato\", sans-serif; }\n\n.Markdown_font-link__3fNP2 {\n  font-family: \"Lato\", sans-serif; }\n\n\n.Markdown_hide-if-only-child__2ZoZR:only-child {\n  display: none; }\n\n\n.Markdown_hide-if-last-child__8NiCL:last-child {\n  display: none; }\n\n\n.Markdown_no-margin-if-last__2nhWH:last-child {\n  margin-bottom: 0; }\n\n\n.Markdown_with-shadow__2joXR {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n\n.Markdown_with-shadow-hover__22YDq {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  \n  .Markdown_with-shadow-hover__22YDq:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.Markdown_wrapper__1KVTR.Markdown_light__gnhRW * {\n  color: #ffffff; }\n\n.Markdown_wrapper__1KVTR.Markdown_light__gnhRW a {\n  color: #ffffff; }\n  .Markdown_wrapper__1KVTR.Markdown_light__gnhRW a:hover {\n    color: #ffffff; }\n\n.Markdown_wrapper__1KVTR.Markdown_large__1l2t0 p,\n.Markdown_wrapper__1KVTR.Markdown_large__1l2t0 a {\n  font-size: 20px; }\n\n.Markdown_wrapper__1KVTR .Markdown_heading1__3ridG {\n  font-size: 24px;\n  font-weight: normal; }\n\n.Markdown_wrapper__1KVTR .Markdown_heading2__11BBT {\n  font-size: 24px; }\n\n.Markdown_wrapper__1KVTR .Markdown_heading3__1Abpi {\n  font-size: 20px; }\n\n.Markdown_wrapper__1KVTR a {\n  font-weight: bold;\n  color: #000000;\n  text-decoration: underline; }\n  .Markdown_wrapper__1KVTR a:hover {\n    color: #000000;\n    text-decoration: none; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "Markdown_font-title__2rX_J",
	"font-body": "Markdown_font-body__2vsOu",
	"font-secondary": "Markdown_font-secondary__3ewOv",
	"font-input": "Markdown_font-input__2FDtG",
	"font-link": "Markdown_font-link__3fNP2",
	"hide-if-only-child": "Markdown_hide-if-only-child__2ZoZR",
	"hide-if-last-child": "Markdown_hide-if-last-child__8NiCL",
	"no-margin-if-last": "Markdown_no-margin-if-last__2nhWH",
	"with-shadow": "Markdown_with-shadow__2joXR",
	"with-shadow-hover": "Markdown_with-shadow-hover__22YDq",
	"wrapper": "Markdown_wrapper__1KVTR",
	"light": "Markdown_light__gnhRW",
	"large": "Markdown_large__1l2t0",
	"heading1": "Markdown_heading1__3ridG",
	"heading2": "Markdown_heading2__11BBT",
	"heading3": "Markdown_heading3__1Abpi"
};