exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Header_wrapper__3tGRg {\n  height: 400px;\n  width: 100%;\n  position: relative; }\n  .Header_wrapper__3tGRg .Header_logoWrapper__zM9x7 {\n    position: absolute;\n    z-index: 2;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 2rem; }\n    .Header_wrapper__3tGRg .Header_logoWrapper__zM9x7 .Header_logo__3T0lc {\n      max-width: 400px;\n      width: 100%; }\n    .Header_wrapper__3tGRg .Header_logoWrapper__zM9x7 .Header_eventName__3FfQn {\n      font-size: 22px;\n      color: white;\n      text-transform: uppercase;\n      font-weight: bold; }\n    .Header_wrapper__3tGRg .Header_logoWrapper__zM9x7 .Header_timeStatus__3JEGS {\n      color: white;\n      font-size: 22px;\n      font-weight: bold; }\n  .Header_wrapper__3tGRg .Header_top__162po {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    z-index: 3;\n    padding: 1rem;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end; }\n    .Header_wrapper__3tGRg .Header_top__162po .Header_user__3UZMW {\n      color: white;\n      font-weight: bold; }\n    .Header_wrapper__3tGRg .Header_top__162po .Header_logout__28K7C {\n      color: white;\n      text-decoration: underline;\n      cursor: pointer;\n      font-weight: bold; }\n\n.Header_bgImage__1Aykz {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  z-index: 1; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "Header_wrapper__3tGRg",
	"logoWrapper": "Header_logoWrapper__zM9x7",
	"logo": "Header_logo__3T0lc",
	"eventName": "Header_eventName__3FfQn",
	"timeStatus": "Header_timeStatus__3JEGS",
	"top": "Header_top__162po",
	"user": "Header_user__3UZMW",
	"logout": "Header_logout__28K7C",
	"bgImage": "Header_bgImage__1Aykz"
};