exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.SubmissionsTable_font-title__2h7G0 {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.SubmissionsTable_font-body__2jH7y {\n  font-family: \"Lato\", sans-serif; }\n\n.SubmissionsTable_font-secondary__1O168 {\n  font-family: \"Lato\", sans-serif; }\n\n.SubmissionsTable_font-input__1YFKg {\n  font-family: \"Lato\", sans-serif; }\n\n.SubmissionsTable_font-link__3GRXN {\n  font-family: \"Lato\", sans-serif; }\n\n\n.SubmissionsTable_hide-if-only-child__3NaWL:only-child {\n  display: none; }\n\n\n.SubmissionsTable_hide-if-last-child__3TRDp:last-child {\n  display: none; }\n\n\n.SubmissionsTable_no-margin-if-last__qbWth:last-child {\n  margin-bottom: 0; }\n\n.SubmissionsTable_wrapper__1zRdD,\n.SubmissionsTable_with-shadow__1lSDk {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n\n.SubmissionsTable_with-shadow-hover__1WsqO {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  \n  .SubmissionsTable_with-shadow-hover__1WsqO:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.SubmissionsTable_wrapper__1zRdD {\n  background: #ffffff; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "SubmissionsTable_font-title__2h7G0",
	"font-body": "SubmissionsTable_font-body__2jH7y",
	"font-secondary": "SubmissionsTable_font-secondary__1O168",
	"font-input": "SubmissionsTable_font-input__1YFKg",
	"font-link": "SubmissionsTable_font-link__3GRXN",
	"hide-if-only-child": "SubmissionsTable_hide-if-only-child__3NaWL",
	"hide-if-last-child": "SubmissionsTable_hide-if-last-child__3TRDp",
	"no-margin-if-last": "SubmissionsTable_no-margin-if-last__qbWth",
	"wrapper": "SubmissionsTable_wrapper__1zRdD",
	"with-shadow": "SubmissionsTable_with-shadow__1lSDk",
	"with-shadow-hover": "SubmissionsTable_with-shadow-hover__1WsqO"
};