exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.ChallengeGridItem_font-title__O-Xyw {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.ChallengeGridItem_font-body__1nOZT {\n  font-family: \"Lato\", sans-serif; }\n\n.ChallengeGridItem_font-secondary__l5Fia {\n  font-family: \"Lato\", sans-serif; }\n\n.ChallengeGridItem_font-input__CCrak {\n  font-family: \"Lato\", sans-serif; }\n\n.ChallengeGridItem_font-link__oNM7y {\n  font-family: \"Lato\", sans-serif; }\n\n\n.ChallengeGridItem_hide-if-only-child__2DISi:only-child {\n  display: none; }\n\n\n.ChallengeGridItem_hide-if-last-child__2hi-c:last-child {\n  display: none; }\n\n\n.ChallengeGridItem_no-margin-if-last__dF5cM:last-child {\n  margin-bottom: 0; }\n\n\n.ChallengeGridItem_with-shadow__fJAdo {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n.ChallengeGridItem_wrapper__3CJHl,\n.ChallengeGridItem_with-shadow-hover__3bYlt {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  .ChallengeGridItem_wrapper__3CJHl:hover,\n  .ChallengeGridItem_with-shadow-hover__3bYlt:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.ChallengeGridItem_wrapper__3CJHl {\n  padding: 1rem;\n  background: #ffffff;\n  border-radius: 7px;\n  cursor: pointer;\n  transition: all 0.2s ease; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "ChallengeGridItem_font-title__O-Xyw",
	"font-body": "ChallengeGridItem_font-body__1nOZT",
	"font-secondary": "ChallengeGridItem_font-secondary__l5Fia",
	"font-input": "ChallengeGridItem_font-input__CCrak",
	"font-link": "ChallengeGridItem_font-link__oNM7y",
	"hide-if-only-child": "ChallengeGridItem_hide-if-only-child__2DISi",
	"hide-if-last-child": "ChallengeGridItem_hide-if-last-child__2hi-c",
	"no-margin-if-last": "ChallengeGridItem_no-margin-if-last__dF5cM",
	"with-shadow": "ChallengeGridItem_with-shadow__fJAdo",
	"wrapper": "ChallengeGridItem_wrapper__3CJHl",
	"with-shadow-hover": "ChallengeGridItem_with-shadow-hover__3bYlt"
};