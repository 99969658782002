exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.Challenge_font-title__9YOxN {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.Challenge_font-body__1FJ7a {\n  font-family: \"Lato\", sans-serif; }\n\n.Challenge_font-secondary__A1hvd {\n  font-family: \"Lato\", sans-serif; }\n\n.Challenge_font-input__1dawJ {\n  font-family: \"Lato\", sans-serif; }\n\n.Challenge_font-link__3X3iI {\n  font-family: \"Lato\", sans-serif; }\n\n\n.Challenge_hide-if-only-child__1LURZ:only-child {\n  display: none; }\n\n\n.Challenge_hide-if-last-child__1NBQy:last-child {\n  display: none; }\n\n\n.Challenge_no-margin-if-last__3bOwW:last-child {\n  margin-bottom: 0; }\n\n.Challenge_challenge__2b4b2, .Challenge_submit__3pd4I,\n.Challenge_with-shadow__1U1zE {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n\n.Challenge_with-shadow-hover__nlbcn {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  \n  .Challenge_with-shadow-hover__nlbcn:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.Challenge_challenge__2b4b2 {\n  background: #ffffff;\n  padding: 1rem; }\n\n.Challenge_row__2oYnC {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end; }\n\n.Challenge_submit__3pd4I {\n  background: #ffffff;\n  padding: 1rem;\n  display: flex;\n  flex-direction: row; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "Challenge_font-title__9YOxN",
	"font-body": "Challenge_font-body__1FJ7a",
	"font-secondary": "Challenge_font-secondary__A1hvd",
	"font-input": "Challenge_font-input__1dawJ",
	"font-link": "Challenge_font-link__3X3iI",
	"hide-if-only-child": "Challenge_hide-if-only-child__1LURZ",
	"hide-if-last-child": "Challenge_hide-if-last-child__1NBQy",
	"no-margin-if-last": "Challenge_no-margin-if-last__3bOwW",
	"challenge": "Challenge_challenge__2b4b2",
	"submit": "Challenge_submit__3pd4I",
	"with-shadow": "Challenge_with-shadow__1U1zE",
	"with-shadow-hover": "Challenge_with-shadow-hover__nlbcn",
	"row": "Challenge_row__2oYnC"
};