exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".CountdownTimer_wrapper__owZrS {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center; }\n  .CountdownTimer_wrapper__owZrS .CountdownTimer_label__CrB-p {\n    font-size: 18px;\n    color: white; }\n\n.CountdownTimer_numbers__umXas {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center; }\n  .CountdownTimer_numbers__umXas .CountdownTimer_number__1JuTo {\n    color: white;\n    font-size: 22px; }\n", ""]);

// Exports
exports.locals = {
	"wrapper": "CountdownTimer_wrapper__owZrS",
	"label": "CountdownTimer_label__CrB-p",
	"numbers": "CountdownTimer_numbers__umXas",
	"number": "CountdownTimer_number__1JuTo"
};