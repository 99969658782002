exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.AdminSubmissionDetail_font-title__n_KZa {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.AdminSubmissionDetail_font-body__2f2DT {\n  font-family: \"Lato\", sans-serif; }\n\n.AdminSubmissionDetail_font-secondary__DZIXH {\n  font-family: \"Lato\", sans-serif; }\n\n.AdminSubmissionDetail_font-input__1qUCJ {\n  font-family: \"Lato\", sans-serif; }\n\n.AdminSubmissionDetail_font-link__1qflt {\n  font-family: \"Lato\", sans-serif; }\n\n\n.AdminSubmissionDetail_hide-if-only-child__18D2P:only-child {\n  display: none; }\n\n\n.AdminSubmissionDetail_hide-if-last-child__1otZO:last-child {\n  display: none; }\n\n\n.AdminSubmissionDetail_no-margin-if-last__1tKF6:last-child {\n  margin-bottom: 0; }\n\n.AdminSubmissionDetail_wrapper__qeXgm,\n.AdminSubmissionDetail_with-shadow__ClSUb {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n\n.AdminSubmissionDetail_with-shadow-hover__GMB2v {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  \n  .AdminSubmissionDetail_with-shadow-hover__GMB2v:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.AdminSubmissionDetail_wrapper__qeXgm {\n  background: white;\n  padding: 1rem; }\n\n.AdminSubmissionDetail_buttons__1oQX_ {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "AdminSubmissionDetail_font-title__n_KZa",
	"font-body": "AdminSubmissionDetail_font-body__2f2DT",
	"font-secondary": "AdminSubmissionDetail_font-secondary__DZIXH",
	"font-input": "AdminSubmissionDetail_font-input__1qUCJ",
	"font-link": "AdminSubmissionDetail_font-link__1qflt",
	"hide-if-only-child": "AdminSubmissionDetail_hide-if-only-child__18D2P",
	"hide-if-last-child": "AdminSubmissionDetail_hide-if-last-child__1otZO",
	"no-margin-if-last": "AdminSubmissionDetail_no-margin-if-last__1tKF6",
	"wrapper": "AdminSubmissionDetail_wrapper__qeXgm",
	"with-shadow": "AdminSubmissionDetail_with-shadow__ClSUb",
	"with-shadow-hover": "AdminSubmissionDetail_with-shadow-hover__GMB2v",
	"buttons": "AdminSubmissionDetail_buttons__1oQX_"
};