exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "/* Junction brand colors */\n/* Junction app colors */\n/* Misc */\n.SubmissionForm_font-title__27qS3 {\n  font-family: \"Montserrat\", sans-serif;\n  font-weight: bold; }\n\n.SubmissionForm_font-body__hCJF9 {\n  font-family: \"Lato\", sans-serif; }\n\n.SubmissionForm_font-secondary__4CtUw {\n  font-family: \"Lato\", sans-serif; }\n\n.SubmissionForm_font-input__sLD3v {\n  font-family: \"Lato\", sans-serif; }\n\n.SubmissionForm_font-link__1L7Nd {\n  font-family: \"Lato\", sans-serif; }\n\n\n.SubmissionForm_hide-if-only-child__2hcVV:only-child {\n  display: none; }\n\n\n.SubmissionForm_hide-if-last-child__3TdzJ:last-child {\n  display: none; }\n\n\n.SubmissionForm_no-margin-if-last__2qgpY:last-child {\n  margin-bottom: 0; }\n\n.SubmissionForm_wrapper__3Mxny,\n.SubmissionForm_with-shadow__3QRNI {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04); }\n\n\n.SubmissionForm_with-shadow-hover__f2qWm {\n  box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.04);\n  position: relative;\n  top: 0;\n  transition: all 0.2s ease; }\n  \n  .SubmissionForm_with-shadow-hover__f2qWm:hover {\n    top: -3px;\n    box-shadow: 2px 7px 30px rgba(0, 0, 0, 0.12); }\n\n.SubmissionForm_wrapper__3Mxny {\n  padding: 1rem;\n  background: #ffffff; }\n\n.SubmissionForm_form__1BfIP {\n  display: flex;\n  flex-direction: row; }\n", ""]);

// Exports
exports.locals = {
	"font-title": "SubmissionForm_font-title__27qS3",
	"font-body": "SubmissionForm_font-body__hCJF9",
	"font-secondary": "SubmissionForm_font-secondary__4CtUw",
	"font-input": "SubmissionForm_font-input__sLD3v",
	"font-link": "SubmissionForm_font-link__1L7Nd",
	"hide-if-only-child": "SubmissionForm_hide-if-only-child__2hcVV",
	"hide-if-last-child": "SubmissionForm_hide-if-last-child__3TdzJ",
	"no-margin-if-last": "SubmissionForm_no-margin-if-last__2qgpY",
	"wrapper": "SubmissionForm_wrapper__3Mxny",
	"with-shadow": "SubmissionForm_with-shadow__3QRNI",
	"with-shadow-hover": "SubmissionForm_with-shadow-hover__f2qWm",
	"form": "SubmissionForm_form__1BfIP"
};